import React, { lazy, Suspense } from 'react'

export const IconLink = lazy(() =>
  import('@veneer/core/dist/scripts/icons/icon_link')
)
export const IconDashboard = lazy(() =>
  import('@veneer/core/dist/scripts/icons/icon_dashboard')
)
export const IconKey = lazy(() =>
  import('@veneer/core/dist/scripts/icons/icon_key')
)
export const IconBuilding = lazy(() =>
  import('@veneer/core/dist/scripts/icons/icon_building')
)
export const IconHome = lazy(() =>
  import('@veneer/core/dist/scripts/icons/icon_home')
)
export const IconLockAccount = lazy(() =>
  import('@veneer/core/dist/scripts/icons/icon_lock_account')
)
export const IconDownload = lazy(() =>
  import('@veneer/core/dist/scripts/icons/icon_download')
)
export const IconCloud = lazy(() =>
  import('@veneer/core/dist/scripts/icons/icon_cloud')
)
export const IconPrinterPin = lazy(() =>
  import('@veneer/core/dist/scripts/icons/icon_printer_pin')
)
export const IconCheckCircle = lazy(() =>
  import('@veneer/core/dist/scripts/icons/icon_check_circle')
)
export const IconPrinterCheck = lazy(() =>
  import('@veneer/core/dist/scripts/icons/icon_printer_check')
)
export const IconLock = lazy(() =>
  import('@veneer/core/dist/scripts/icons/icon_lock')
)
export const IconPrintPrivate = lazy(() =>
  import('@veneer/core/dist/scripts/icons/icon_print_private')
)
export const IconShield = lazy(() =>
  import('@veneer/core/dist/scripts/icons/icon_shield')
)
export const IconTruck = lazy(() =>
  import('@veneer/core/dist/scripts/icons/icon_truck')
)
export const IconPrinter = lazy(() =>
  import('@veneer/core/dist/scripts/icons/icon_printer')
)
export const IconPeople = lazy(() =>
  import('@veneer/core/dist/scripts/icons/icon_people')
)
export const IconDocumentScan = lazy(() =>
  import('@veneer/core/dist/scripts/icons/icon_document_scan')
)
export const IconDocumentScanCloud = lazy(() =>
  import('@veneer/core/dist/scripts/icons/icon_document_scan_cloud')
)
export const IconFax = lazy(() =>
  import('@veneer/core/dist/scripts/icons/icon_fax')
)
export const IconMinusCircle = lazy(() =>
  import('@veneer/core/dist/scripts/icons/icon_minus_circle')
)
export const IconWarningAlt = lazy(() =>
  import('@veneer/core/dist/scripts/icons/icon_warning_alt')
)
export const IconInfo = lazy(() =>
  import('@veneer/core/dist/scripts/icons/icon_info')
)
export const IconStar = lazy(() =>
  import('@veneer/core/dist/scripts/icons/icon_star')
)
export const IconApplication = lazy(() =>
  import('@veneer/core/dist/scripts/icons/icon_application')
)
export const IconAdv = lazy(() =>
  import('@veneer/core/dist/scripts/icons/icon_adv')
)
export const IconGlobe = lazy(() =>
  import('@veneer/core/dist/scripts/icons/icon_globe')
)
export const IconDevicesLaptopPrinter = lazy(() =>
  import('@veneer/core/dist/scripts/icons/icon_devices_laptop_printer')
)
export const IconContacts = lazy(() =>
  import('@veneer/core/dist/scripts/icons/icon_contacts')
)
export const IconDashboardApplication = lazy(() =>
  import('@veneer/core/dist/scripts/icons/icon_dashboard_application')
)
export const IconEnvelope = lazy(() =>
  import('@veneer/core/dist/scripts/icons/icon_envelope')
)
export const IconBell = lazy(() =>
  import('@veneer/core/dist/scripts/icons/icon_bell')
)
export const IconEnvelopeOpen = lazy(() =>
  import('@veneer/core/dist/scripts/icons/icon_envelope_open')
)
export const IconPerson = lazy(() =>
  import('@veneer/core/dist/scripts/icons/icon_person')
)
export const IconPrinterShield = lazy(() =>
  import('@veneer/core/dist/scripts/icons/icon_printer_shield')
)

export const CmsVeneerIcons = {
  Key: IconKey,
  Building: IconBuilding,
  Home: IconHome,
  LockAccount: IconLockAccount,
  Download: IconDownload,
  Cloud: IconCloud,
  PrinterPin: IconPrinterPin,
  Truck: IconTruck,
  PrinterCheckmark: IconPrinterCheck,
  Link: IconLink,
  Dashboard: IconDashboard,
  PrintPrivate: IconPrintPrivate,
  Lock: IconLock,
  Shield: IconShield,
  Person: IconPerson,
  People: IconPeople,
  Printer: IconPrinter,
  PrinterShield: IconPrinterShield,
  DocumentScan: IconDocumentScan,
  Info: IconInfo,
  Adv: IconAdv,
  Globe: IconGlobe,
  DevicesLaptopPrinter: IconDevicesLaptopPrinter,
  Contacts: IconContacts,
  DashboardApplication: IconDashboardApplication,
  Envelope: IconEnvelope,
  Bell: IconBell,
  EnvelopeOpen: IconEnvelopeOpen
}

export const CmsVeneerIcon = ({ icon, size = 24 }) => {
  const VeneerIcon = CmsVeneerIcons[icon]
  return (
    <Suspense fallback={<></>}>
      <VeneerIcon size={size} />
    </Suspense>
  )
}
