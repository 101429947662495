import { Stack } from '@jarvis/web-stratus-client'
import FirmwareUpdateConfigBodyXml from './DeviceHttpProxyLedmBody/FirmwareUpdateConfig.xml'

export const SMB_EXPERIENCE = 'smb'
export const ECP_EXPERIENCE = 'ecp'
export const VENEER_TERTIARY_BUTTON_APPEARANCE = 'ghost'
export const VENEER_SECONDARY_BUTTON_APPEARANCE = 'secondary'
export const VENEER_PRIMARY_BUTTON_APPEARANCE = 'primary'
export const TRADITIONAL_SUPPORT_URL = 'https://hp.com/support/printer-setup'
export const HP_PLUS_SUPPORT_URL = 'https://hp.com/plus-pro-support'
export const HP_EASY_START_DOWNLOAD_URL = 'https://hpsmart.com/setup'
export const ONBOARDING_CENTER_DOMAIN_PIE =
  'onboardingcenter.pie.portalshell.int.hp.com'
export const ONBOARDING_CENTER_DOMAIN_STAGE =
  'onboardingcenter.stage.portalshell.int.hp.com'
export const ONBOARDING_CENTER_DOMAIN_PROD = 'start.hp.com'
export const HP_SMART_SOFTWARE_URL = 'https://hpsmart.com/get-software'
export const ACCOUNT_TYPE_COMPANY = 'BusinessTransactionalSMB'
export const ACCOUNT_TYPE_MPS_COMPANY = 'ChannelMPSCompany'
export const ACCOUNT_TYPE_PERSONAL = 'Personal'
export const UCDE_PROGRAM_ID = 'UCDE'
export const YETI_PROGRAM_ID = 'YETI'
export const BIZ_MODEL_STANDARD = 'Flex'
export const BIZ_MODEL_HP_PLUS = 'E2E'
export const ENTRY_PORTAL_OOBE = 'portalOobe'
export const ENTRY_SCAN_SETUP = 'portalScanSetup'
export const ENTRY_APP_OOBE = 'appOobe'
export const ENTRY_EARLY_OOBE = 'earlyOobe'
export const PRODUCT_FAMILY_LFP = 'LFP_DUNE'
export const HP_PLUS_PROGRAM_LEVEL = 'hpPlus'
export const STANDARD_PROGRAM_LEVEL = 'standard'
export const OFFER_HINT_DUAL_SKU = 'dualSku'
export const OFFER_HINT_SINGLE_SKU = 'singleSku'
export const SINGLE_SKU_OFFER = 1
export const FW_UPDATE_AUTO = 'auto'
export const FW_UPDATE_NOTIFY = 'notify'
export const HP_ONE_ID = '357e06c1-a567-42ba-9548-d57f37e34f0f'
export const MULTI_FUNCTION_PRINTER = 'MFP'
export const SINGLE_FUNCTION_PRINTER = 'SFP'
export const DUNE_DESIGN_JET = 'duneDesignJet'
export const CDM_DEVICE = 'cdmOnly'
export const CDM_DISCOVERY_TREE = '/cdm/servicesDiscovery'
export const LEDM_DISCOVERY_TREE = '/DevMgmt/DiscoveryTree.xml'
export const ITALY_COUNTRY_CODE = 'it'
export const FALLBACK_PRINTER_COUNTRY = ITALY_COUNTRY_CODE
export const CDM_ITALY = ITALY_COUNTRY_CODE
export const LEDM_ITALY = 'italy'
export const REST_OF_WORLD = 'row'
export const JSON_CONTENT_TYPE = 'json'
export const XML_CONTENT_TYPE = 'xml'
export const RESPONSIVE_TABLET_SIZE = 767
export const HUB_NAVIGATION_SERVICE =
  'https://h20180.www2.hp.com/apps/Nav?h_client=S-A-10020-1&h_pagetype=s-002&openCLC=true'

export const CONTENT_STACK_STACKS = {
  api_key: 'blt8a8e334f0a83fb21',
  pie: {
    delivery_token: 'cs8e4d4c476111bdad9ef3a077',
    management_token: 'cseb300222a2c000dbe299725c',
    env_name: 'pie'
  },
  stage: {
    delivery_token: 'cs1d8958ab4c6be46a38350bc4',
    env_name: 'stage'
  },
  prod: {
    delivery_token: 'csf5dd829a1a24c27be970a285',
    env_name: 'prod'
  }
}

const GENERIC_MODAL_TYPE = 'generic_modal'

export const CONTENT_STACK_TYPES = {
  account_confirm: {
    content_type_uid: 'account_confirm'
  },
  hp_plus_offer_benefits: {
    content_type_uid: 'hp_offer_benefits'
  },
  hp_smart_admin: {
    content_type_uid: 'hp_smart_admin'
  },
  install_drivers_windows: {
    content_type_uid: 'install_drivers_windows'
  },
  install_drivers_non_windows: {
    content_type_uid: 'install_drivers_non_windows'
  },
  install_printing_software: {
    content_type_uid: 'install_printing_software'
  },
  first_print: {
    content_type_uid: 'first_print'
  },
  value_prop: {
    content_type_uid: 'value_prop_landing_page'
  },
  new_value_prop: {
    content_type_uid: 'new_value_prop_landing_page'
  },
  pairing_code: {
    content_type_uid: 'pairing_code'
  },
  activation: {
    content_type_uid: 'activation'
  },
  account_type: {
    content_type_uid: 'account_type_page'
  },
  activating: {
    content_type_uid: 'activating_page'
  },
  iris_firmware_update: {
    content_type_uid: 'iris_firmware_update'
  },
  partner_link: {
    content_type_uid: 'partner_link'
  },
  error_modal: {
    content_type_uid: 'error_modal'
  },
  decline_hp_plus_offer_modal: {
    content_type_uid: GENERIC_MODAL_TYPE,
    entry_uid: 'blt302beb59ec6a2bc3'
  },
  learn_more_modal_personal: {
    content_type_uid: GENERIC_MODAL_TYPE,
    entry_uid: 'bltaba7b3160f7e86a3'
  },
  learn_more_modal_business: {
    content_type_uid: GENERIC_MODAL_TYPE,
    entry_uid: 'blt3fe8dd5ec6ff383b'
  },
  learn_more_hp_plus_overview_modal: {
    content_type_uid: GENERIC_MODAL_TYPE,
    entry_uid: 'blt68d3594e1f25e09d'
  },
  learn_more_hp_plus_requirements_modal: {
    content_type_uid: GENERIC_MODAL_TYPE,
    entry_uid: 'bltcef9aa6d52629805'
  },
  learn_more_instant_ink_modal: {
    content_type_uid: GENERIC_MODAL_TYPE,
    entry_uid: 'blta614e88007e5f225'
  },
  learn_more_forest_first_modal: {
    content_type_uid: GENERIC_MODAL_TYPE,
    entry_uid: 'bltaadc4ccb86310e78'
  },
  learn_more_hp_smart_advance_modal: {
    content_type_uid: GENERIC_MODAL_TYPE,
    entry_uid: 'bltf3058a52085719d2'
  },
  learn_more_smart_security_modal: {
    content_type_uid: GENERIC_MODAL_TYPE,
    entry_uid: 'bltf612497a6160be1a'
  },
  learn_more_hp_smart_pro_modal: {
    content_type_uid: GENERIC_MODAL_TYPE,
    entry_uid: 'blt186c4e643476b34c'
  },
  learn_more_hp_plus_overview_save_on_ink_modal: {
    content_type_uid: GENERIC_MODAL_TYPE,
    entry_uid: 'blta3859cd2e0a4ea0a'
  },
  pdsn_modal: {
    content_type_uid: GENERIC_MODAL_TYPE,
    entry_uid: 'bltca93fa27028f5140'
  },
  firmware_update_choice: {
    content_type_uid: 'firmware_update_choice'
  },
  assign_organization: {
    content_type_uid: 'assign_organization'
  },
  reconsider_modal: {
    content_type_uid: GENERIC_MODAL_TYPE
  },
  printer_name_location: {
    content_type_uid: 'printer_name_location'
  },
  finish_setup_ecp: {
    content_type_uid: 'finish_setup_ecp'
  },
  delta_access_page: {
    content_type_uid: 'delta_access_page'
  },
  landing_page_app: {
    content_type_uid: 'landing_page_app'
  },
  exit_hp_cloud_connection_mfp: {
    content_type_uid: GENERIC_MODAL_TYPE,
    entry_uid: 'bltb15e8c960b9a70e2'
  },
  exit_hp_cloud_connection_sfp: {
    content_type_uid: GENERIC_MODAL_TYPE,
    entry_uid: 'bltec467cf72d4c90ec'
  },
  hp_smart_admin_value_prop_app: {
    content_type_uid: 'hp_smart_admin',
    entry_uid: 'bltf9332ccdaa5a3e1e'
  }
}

export const ISO_VALUES = {
  AD: 'Andorra',
  AE: 'الإمارات العربية',
  AF: 'Afghanistan',
  AG: 'Antigua and Barbuda',
  AI: 'Anguilla',
  AL: 'Albania',
  AM: 'Armenia',
  AN: 'Netherlands Antilles',
  AO: 'Angola',
  AR: 'Argentina',
  AS: 'American Samoa',
  AT: 'Österreich',
  AU: 'Australia',
  AW: 'Aruba',
  AZ: 'Azerbaijan',
  BA: 'Bosnia and Herzegovina',
  BB: 'Barbados',
  BD: 'Bangladesh',
  BE: 'België',
  BF: 'Burkina Faso',
  BG: 'България',
  BH: 'Bahrain',
  BI: 'Burundi',
  BJ: 'Benin',
  BL: 'Saint Martin',
  BM: 'Bermuda',
  BN: 'Brunei',
  BO: 'Bolivia',
  BQ: 'Curaçao',
  BR: 'Brasil',
  BS: 'The Bahamas',
  BT: 'Bhutan',
  BW: 'Botswana',
  BY: 'Беларусь',
  BZ: 'Belize',
  CA: 'Canada',
  CC: 'Cocos Keeling Islands',
  CD: 'République démocratique du Congo',
  CF: 'Central African Republic',
  CG: 'Congo',
  CH: 'Schweizerische',
  CI: "Côte d'Ivoire",
  CK: 'Cook Islands',
  CL: 'Chile',
  CM: 'Cameroon',
  CN: '中国',
  CO: 'Colombia',
  CR: 'Costa Rica',
  CS: 'Serbia and Montenegro',
  CU: 'Cuba',
  CV: 'Cape Verde',
  CW: 'Saint Barthélemy',
  CY: 'Cyprus',
  CZ: 'Česká republika',
  DE: 'Deutschland',
  DG: 'Diego Garcia',
  DJ: 'Djibouti',
  DK: 'Danmark',
  DM: 'Dominica',
  DO: 'República Dominicana',
  DZ: 'Algeria',
  EC: 'Ecuador',
  EE: 'Estonia',
  EG: 'مصر',
  ER: 'Eritrea',
  ES: 'España',
  ET: 'Ethiopia',
  FI: 'Suomi',
  FJ: 'Fiji Islands',
  FK: 'Falkland Islands Islas Malvinas',
  FM: 'Micronesia',
  FO: 'Faroe Islands',
  FR: 'France',
  GA: 'Gabon',
  GB: 'United Kingdom',
  GD: 'Grenada',
  GE: 'Georgia',
  GF: 'French Guiana',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GL: 'Greenland',
  GM: 'The Gambia',
  GN: 'Guinea',
  GP: 'Guadeloupe',
  GQ: 'Guinea Ecuatorial',
  GR: 'Ελλάς',
  GT: 'Guatemala',
  GU: 'Guam',
  GW: 'Guinea Bissau',
  GY: 'Guyana',
  HK: '香港特別行政區',
  HN: 'Honduras',
  HR: 'Hrvatska',
  HT: 'Haiti',
  HU: 'Magyarország',
  ID: 'Indonesia',
  IE: 'Ireland',
  IL: 'יִשְׂרָאֵל',
  IN: 'India',
  IQ: 'Iraq',
  IR: 'Iran',
  IS: 'Iceland',
  IT: 'Italia',
  JM: 'Jamaica',
  JO: 'الأردن',
  JP: '日本',
  KE: 'Kenya',
  KG: 'Kyrgyzstan',
  KH: 'Cambodia',
  KI: 'Kiribati',
  KM: 'Comoros',
  KN: 'St Kitts and Nevis',
  KP: 'North Korea',
  KR: '한국',
  KW: 'كويت',
  KY: 'Cayman Islands',
  KZ: 'Казахстан',
  LA: 'Laos',
  LB: 'لبنان',
  LC: 'St Lucia',
  LI: 'Liechtenstein',
  LK: 'Sri Lanka',
  LR: 'Liberia',
  LS: 'Lesotho',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  LV: 'Latvia',
  LY: 'Libya',
  MA: 'المغرب',
  MC: 'Monaco',
  MD: 'Moldova',
  ME: 'Montenegro',
  MF: 'Sint Maarten',
  MG: 'Madagascar',
  MH: 'Marshall Islands',
  MK: 'Macedonia',
  ML: 'Mali',
  MM: 'Myanmar',
  MN: 'Mongolia',
  MO: 'Macao',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MS: 'Montserrat',
  MT: 'Malta',
  MU: 'Mauritius',
  MV: 'Maldives',
  MW: 'Malawi',
  MX: 'México',
  MY: 'Malaysia',
  MZ: 'Moçambique',
  NA: 'Namibia',
  NC: 'New Caledonia',
  NE: 'Niger',
  NF: 'Norfolk Island',
  NG: 'Nigeria',
  NI: 'Nicaragua',
  NL: 'Nederland',
  NO: 'Norge',
  NP: 'Nepal',
  NR: 'Nauru',
  NU: 'Niue',
  NZ: 'New Zealand',
  OM: 'Oman',
  PA: 'Panamá',
  PE: 'Perú',
  PF: 'French Polynesia',
  PG: 'Papua New Guinea',
  PH: 'Philippines',
  PK: 'Pakistan',
  PL: 'Polska',
  PM: 'St Pierre and Miquelon',
  PR: 'Puerto Rico',
  PT: 'Portugal',
  PW: 'Palau',
  PY: 'Paraguay',
  QA: 'قطر',
  RE: 'Reunion',
  RO: 'România',
  RS: 'Serbia and Montenegro',
  RU: 'Росси́я',
  RW: 'Rwanda',
  SA: 'المملكة العربية السعودية',
  SB: 'Solomon Islands',
  SC: 'Seychelles',
  SD: 'Sudan',
  SE: 'Sverige',
  SG: 'Singapore',
  SH: 'St Helena',
  SI: 'Slovenija',
  SK: 'Slovensko',
  SM: 'San Marino',
  SN: 'Senegal',
  SO: 'Somalia',
  SR: 'Suriname',
  SV: 'El Salvador',
  SX: 'Caribbean Netherlands',
  SY: 'Syria',
  SZ: 'Swaziland',
  TC: 'Turks and Caicos Islands',
  TD: 'Chad',
  TG: 'Togo',
  TH: 'ไทย',
  TJ: 'Tajikistan',
  TK: 'Tokelau',
  TL: 'Timor Leste',
  TM: 'Turkmenistan',
  TN: 'تونس',
  TO: 'Tonga',
  TR: 'Türkiye',
  TT: 'Trinidad and Tobago',
  TV: 'Tuvalu',
  TW: '臺灣地區',
  TZ: 'Tanzania',
  UA: 'Україна',
  UG: 'Uganda',
  US: 'United States',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VA: 'Vatican City',
  VC: 'St Vincent and the Grenadines',
  VE: 'Venezuela',
  VG: 'British Virgin Islands ',
  VI: 'Virgin Islands',
  VN: 'Vietnam',
  VU: 'Vanuatu',
  WF: 'Wallis and Futuna',
  WS: 'Samoa',
  YE: 'اليمن',
  YT: 'Mayotte',
  ZA: 'South Africa',
  ZM: 'Zambia',
  ZW: 'Zimbabwe'
}

const BASE_PATH = '/PortalOobe/'
const ACTIVITY = {
  PAIRING: 'PairingCode-v01',
  CONFIGURE: 'ConfigureAndTest-v01',
  ASSIGN: 'AssignOwnership-v01',
  ALL: 'All-v01',
  INSTALL_DRIVER: 'InstallDriversandTestPage-v01',
  HP_SMART_ADMIN: 'HpSmartAdminHandOff-v01',
  INSTANT_INK: 'InstantInk-v01',
  INSTALL_PRINT_SOFTWARE: 'InstallPrintSoftware-v01',
  FIRMWARE_UPDATE: 'FirmwareUpdate-v01'
}
const ERROR_CODE = 'ErrorCode'
export const ANALYTICS = {
  ACTIVITY,
  BASE_PATH,
  ERROR_CODE,
  SCREENS: {
    PRINT_TEST_PAGE: {
      screenName: 'PrintTestPage',
      activity: ACTIVITY.INSTALL_DRIVER,
      screenPath: `${BASE_PATH}`
    },
    SETUP_PRINT: {
      screenName: 'SetUpPrint',
      activity: ACTIVITY.CONFIGURE,
      screenPath: `${BASE_PATH}FinishPrinterSetup/`
    },
    INSTALL_DRIVER_OTHER: {
      screenName: 'InstallDriverOther',
      activity: ACTIVITY.INSTALL_DRIVER,
      screenPath: `${BASE_PATH}`
    },
    INSTALL_DRIVER_WINDOWS: {
      screenName: 'InstallDriverWindows',
      activity: ACTIVITY.INSTALL_DRIVER,
      screenPath: `${BASE_PATH}`
    },
    INSTALL_PRINTING_SOFTWARE: {
      screenName: 'InstallSoftware',
      activity: ACTIVITY.INSTALL_PRINT_SOFTWARE,
      screenPath: `${BASE_PATH}`
    },
    SMART_ADMIN_VALUE_PROP: {
      screenName: 'SmartAdminValueProp',
      activity: ACTIVITY.HP_SMART_ADMIN,
      screenPath: `${BASE_PATH}`
    },
    SMART_ADMIN_VALUE_PROP_PERSONAL: {
      screenName: 'SmartAdminValuePropPersonal',
      activity: ACTIVITY.HP_SMART_ADMIN,
      screenPath: `${BASE_PATH}`
    },
    SETUP_PRINT_DRIVER_MODAL: {
      screenName: 'DriverInstallationModal',
      activity: ACTIVITY.CONFIGURE,
      screenPath: `${BASE_PATH}SetUpPrint/`
    },
    COMPANY_NAME: {
      screenName: 'CompanyName',
      activity: ACTIVITY.ASSIGN,
      screenPath: BASE_PATH
    },
    DOMAIN_ERROR_MODAL: {
      screenName: 'DomainErrorModal',
      activity: ACTIVITY.ASSIGN,
      screenPath: `${BASE_PATH}CompanyName/`
    },
    ACCOUNT_CONFIRM: {
      screenName: 'AccountConfirm',
      activity: ACTIVITY.ASSIGN,
      screenPath: BASE_PATH
    },
    PAIRING_CODE_DOWN_ERROR: {
      screenName: 'PairingCodeDownError',
      activity: ACTIVITY.PAIRING,
      screenPath: `${BASE_PATH}EnterPairCode/`
    },
    EXPIRED_CODE_ERROR: {
      screenName: 'ExpiredCodeError',
      activity: ACTIVITY.PAIRING,
      screenPath: `${BASE_PATH}EnterPairCode/`
    },
    FIRMWARE_UPDATE: {
      screenName: 'FirmwareUpdate',
      activity: ACTIVITY.INSTANT_INK,
      screenPath: BASE_PATH
    },
    FIRMWARE_UPDATE_CHOICE: {
      screenName: 'FirmwareUpdateChoice',
      activity: ACTIVITY.FIRMWARE_UPDATE,
      screenPath: BASE_PATH
    },
    INCORRECT_FLOW_MODAL: {
      screenName: 'WrongPrinterError',
      activity: ACTIVITY.PAIRING,
      screenPath: `${BASE_PATH}EnterPairCode/`
    },
    USER_ALREADY_EXISTS_ACTIVATE: {
      screenName: 'UserAlreadyExistsActivate',
      activity: ACTIVITY.ALL,
      screenPath: BASE_PATH
    },
    USER_ALREADY_EXISTS_CONNECT: {
      screenName: 'UserAlreadyExistsConnect',
      activity: ACTIVITY.ALL,
      screenPath: BASE_PATH
    },
    USER_ALREADY_EXISTS_ONBOARD: {
      screenName: 'UserAlreadyExistsOnboard',
      activity: ACTIVITY.ALL,
      screenPath: BASE_PATH
    },
    FINISH_PRINTER_SETUP: {
      screenName: 'FinishPrinterSetup',
      activity: ACTIVITY.CONFIGURE,
      screenPath: BASE_PATH
    },
    ACTIVATE: {
      screenName: 'Activate',
      screenPath: BASE_PATH
    },
    CONNECT: {
      screenName: 'Connect',
      screenPath: BASE_PATH
    },
    SINGLE_SKU: {
      screenName: 'Onboard',
      screenPath: BASE_PATH
    },
    LFP: {
      screenName: 'Go',
      screenPath: BASE_PATH
    },
    SCAN_SETUP: {
      screenName: 'StartScan',
      screenPath: BASE_PATH
    },
    BASIC_SETUP_CONNECT: {
      screenName: 'BasicSetup',
      screenPath: `${BASE_PATH}Connect/`
    },
    PERSONAL_SETUP_SINGLE_SKU: {
      screenName: 'PersonalSetup',
      screenPath: `${BASE_PATH}Onboard/`
    },
    FEATURE_MODAL_CONNECT: {
      screenName: 'FeatureModal',
      screenPath: `${BASE_PATH}Connect/`
    },
    FEATURE_MODAL_ACTIVATE: {
      screenName: 'FeatureModal',
      screenPath: `${BASE_PATH}Activate/`
    },
    FEATURE_MODAL_SINGLE_SKU: {
      screenName: 'FeatureModal',
      screenPath: `${BASE_PATH}Onboard/`
    },
    FEATURE_MODAL_SCAN_SETUP: {
      screenName: 'StartScan',
      screenPath: `${BASE_PATH}StartScan/`
    },
    ACCOUNT_TYPE: {
      screenName: 'AccountType',
      activity: ACTIVITY.ASSIGN,
      screenPath: BASE_PATH
    },
    ENTER_PAIRING_CODE: {
      screenName: 'EnterPairingCode',
      activity: ACTIVITY.PAIRING,
      screenPath: BASE_PATH
    },
    PAIRING_CODE_INLINE_ERROR: {
      screenName: 'PairingCodeInlineError',
      activity: ACTIVITY.PAIRING,
      screenPath: BASE_PATH
    },
    PRINTER_NAME: {
      screenName: 'PrinterName',
      activity: ACTIVITY.PAIRING,
      screenPath: BASE_PATH
    },
    REQUIREMENTS: {
      screenName: 'AckHpPlus',
      activity: ACTIVITY.PAIRING,
      screenPath: BASE_PATH
    },
    REQUIREMENTS_COUNTRY_SUPPORTED_LIST: {
      screenName: 'CountrySupportedList',
      activity: ACTIVITY.PAIRING,
      screenPath: `${BASE_PATH}AckHpPlus/`
    },
    ACTIVATION_ERROR_GENERIC: {
      screenName: 'ActivationErrorGeneric',
      activity: ACTIVITY.PAIRING,
      screenPath: `${BASE_PATH}PrinterActivationProgress/`
    },
    GENERIC_ERROR: {
      screenName: 'GenericError',
      activity: ACTIVITY.ALL,
      screenPath: BASE_PATH
    },
    ACTIVATION_ERROR_PAIRING_TOKEN: {
      screenName: 'ActivationErrorPairingToken',
      activity: ACTIVITY.PAIRING,
      screenPath: `${BASE_PATH}PrinterActivationProgress/`
    },
    ADMIN_TOU: {
      screenName: 'AdminTou',
      activity: ACTIVITY.ASSIGN,
      screenPath: BASE_PATH
    },
    DOMAIN_ERROR_INLINE: {
      screenName: 'DomainErrorInline',
      activity: ACTIVITY.ASSIGN,
      screenPath: BASE_PATH
    },
    PAIRING_CODE_SUCCESS: {
      screenName: 'PairingCodeSuccess',
      activity: ACTIVITY.PAIRING,
      screenPath: BASE_PATH
    },
    CODE_LIMIT_ERROR: {
      screenName: 'CodeLimitError',
      activity: ACTIVITY.PAIRING,
      screenPath: `${BASE_PATH}PairingCodeInlineError/`
    },
    PRINTER_ACTIVATION_PROGRESS: {
      screenName: 'PrinterActivationProgress',
      activity: ACTIVITY.PAIRING,
      screenPath: BASE_PATH
    },
    PRINTER_ACTIVATION_SUCCESS: {
      screenName: 'PrinterActivationSuccess',
      activity: ACTIVITY.PAIRING,
      screenPath: BASE_PATH
    },
    COUNTRY_UNSUPPORTED_ERROR: {
      screenName: 'CountryUnsupportedError',
      activity: ACTIVITY.ASSIGN,
      screenPath: BASE_PATH
    },
    COUNTRY_UNSUPPORTED_ERROR_MODAL: {
      screenName: 'CountryUnsupportedErrorModal',
      activity: ACTIVITY.ASSIGN,
      screenPath: BASE_PATH
    },
    COUNTRY_UNSUPPORTED_ERROR_SUPPORTED_LIST: {
      screenName: 'CountrySupportedList',
      activity: ACTIVITY.ASSIGN,
      screenPath: `${BASE_PATH}CountryUnsupportedError/`
    },
    HP_PLUS_VALUE_PROP: {
      screenName: 'HpPlusValueProp',
      activity: ACTIVITY.PAIRING,
      screenPath: BASE_PATH
    },
    IRIS: {
      screenName: 'Iris',
      activity: ACTIVITY.ASSIGN,
      screenPath: BASE_PATH
    },
    IRIS_RECONSIDER_MODAL: {
      screenName: 'ReconsiderModal',
      activity: ACTIVITY.ASSIGN,
      screenPath: `${BASE_PATH}Iris/`
    },
    RECONSIDER_ACTIVATION_MODAL: {
      screenName: 'ReconsiderModal',
      activity: ACTIVITY.PAIRING,
      screenPath: `${BASE_PATH}PrinterActivationProgress/`
    },
    RECONSIDER_PAIRING_MODAL: {
      screenName: 'DagReconsiderModal',
      activity: ACTIVITY.PAIRING,
      screenPath: `${BASE_PATH}EnterPairCode/`
    },
    CANT_FIND_PAIRING_CODE: {
      screenName: 'CantFindPairCodeModal',
      activity: ACTIVITY.PAIRING,
      screenPath: `${BASE_PATH}EnterPairCode/`
    },
    GET_PAIR_CODE_THROUGH_NETWORK: {
      screenName: 'GetPairCodeThroughNetworkModal',
      activity: ACTIVITY.PAIRING,
      screenPath: `${BASE_PATH}EnterPairCode/CantFindPairCodeModal/`
    },
    GET_PAIR_CODE_THROUGH_EWS: {
      screenName: 'GetPairCodeThroughEwsModal',
      activity: ACTIVITY.PAIRING,
      screenPath: `${BASE_PATH}EnterPairCode/CantFindPairCodeModal/`
    },
    DECLINE_HP_PLUS_MODAL: {
      screenName: 'DeclineHpPlusOfferModal',
      activity: ACTIVITY.PAIRING,
      screenPath: `${BASE_PATH}HpPlusOptInOut/`
    },
    PDSN_MODAL: {
      screenName: 'SecurityNoticeModal',
      activity: ACTIVITY.PAIRING,
      screenPath: `${BASE_PATH}HpPlusOptInOut/`
    },
    LEARN_MORE_MODAL: {
      screenName: 'LearnMoreModal',
      activity: ACTIVITY.PAIRING,
      screenPath: `${BASE_PATH}HpPlusValueProp/`
    },
    HP_PLUS_OVERVIEW_MODAL: {
      screenName: 'HpPlusOverviewModal',
      activity: ACTIVITY.PAIRING,
      screenPath: `${BASE_PATH}HpPlusValueProp/`
    },
    HP_PLUS_REQUIREMENTS_MODAL: {
      screenName: 'HpPlusRequirementsModal',
      activity: ACTIVITY.PAIRING,
      screenPath: `${BASE_PATH}HpPlusValueProp/`
    },
    INSTANT_INK_OVERVIEW_MODAL: {
      screenName: 'InstantInkOverviewModal',
      activity: ACTIVITY.PAIRING,
      screenPath: `${BASE_PATH}HpPlusValueProp/`
    },
    FOREST_FIRST_PRINTING_OVERVIEW_MODAL: {
      screenName: 'ForestFirstPrintingOverviewModal',
      activity: ACTIVITY.PAIRING,
      screenPath: `${BASE_PATH}HpPlusValueProp/`
    },
    HP_SMART_ADVANCE_OVREVIEW_MODAL: {
      screenName: 'HpSmartAdvanceOverviewModal',
      activity: ACTIVITY.PAIRING,
      screenPath: `${BASE_PATH}HpPlusValueProp/`
    },
    HP_SMART_PRO_OVERVIEW_MODAL: {
      screenName: 'HpSmartProOverviewModal',
      activity: ACTIVITY.PAIRING,
      screenPath: `${BASE_PATH}HpPlusValueProp/`
    },
    SMART_SECURITY_OVERVIEW_MODAL: {
      screenName: 'SmartSecurityOverviewModal',
      activity: ACTIVITY.PAIRING,
      screenPath: `${BASE_PATH}HpPlusValueProp/`
    },
    DEVICE_IS_PAAS_ERROR_MODAL: {
      screenName: 'ActivationPaasError',
      activity: ACTIVITY.PAIRING,
      screenPath: `${BASE_PATH}PrinterActivationProgress/`
    },
    UNSUPPORTED_LOCALE: {
      screenName: 'UnsupportedLocale',
      activity: ACTIVITY.ALL,
      screenPath: BASE_PATH
    },
    UNABLE_TO_LOAD_PAGE: {
      screenName: 'UnableToLoadPage',
      activity: ACTIVITY.ALL,
      screenPath: BASE_PATH
    },
    SCAN_TO_EMAIL_CREATION_ERROR: {
      screenName: 'ScanToEmailCreationError',
      activity: ACTIVITY.PAIRING,
      screenPath: `${BASE_PATH}ScanToEmailCreationFailed/`
    }
  },
  BUTTONS: {
    CONTINUE: 'ContinueButton',
    SIGN_IN: 'SignInButton',
    CREATE_ACCOUNT: 'CreateAccountButton',
    TRY_AGAIN: 'TryAgainButton',
    SUPPORT: 'HpSupportButton',
    CONFIRM: 'ConfirmButton',
    CREATE_ACC_SIGN_IN: 'CreateAccSignInButton',
    PRINTER_NAME: 'PrinterNameText',
    PRINTER_LOC: 'PrinterLocText',
    SKIP: 'SkipButton',
    GO_TO_SMART_ADMIN: 'GoToSmartAdminButton',
    SETUP_PRINT: 'SetUpPrintButton',
    SETUP_SCAN: 'SetUpScanDestinationsButton',
    SETUP_FAX: 'SetUpFaxButton',
    INVITE_USERS_ADMINS: 'InviteUsersAdminsButton',
    SETUP_SCAN_CLOSE: 'SetUpScanDestinationsCloseButton',
    INVITE_USERS_ADMINS_CLOSE: 'InviteUsersAdminsCloseButton',
    INSTALL_HP_SMART: 'InstallHpSmartButton',
    PRINT_TEST_PAGE: 'PrintTestPageButton',
    ENABLE_PRINT_ANYWHERE: 'PrintAnywhereToggle',
    REQUIRE_PRIVATE_PICKUP: 'RequirePrivatePickupToggle',
    SETUP_PRINT_SEND_TEST_PAGE_DONE: 'SetUpPrintSendTestPageDoneButton',
    ORG_NAME: 'OrgNameText',
    ORG_DESC: 'OrgDescText',
    RADIO: 'RadioButton',
    SELECT_COUNTRY: 'SelectCountryDropDown',
    OK: 'OkButton',
    INSTALL_DRIVER: 'InstallDriverButton',
    CLOSE: 'CloseButton',
    BACK: 'BackButton',
    CONTINUE_SETUP: 'ContinueSetupButton',
    CANCEL_SETUP: 'CancelSetupButton',
    CANCEL_SETUP_MANUAL: 'CancelSetupManualButton',
    CONFIRM_CONTINUE: 'ConfirmContinueButton',
    COPY_TEMPLATE: 'CopyTemplateButton',
    APPLY: 'ApplyButton',
    HP_SMART_ADMIN: 'HpSmartAdminButton',
    COMPANY_SELECT_BUTTON: 'CompanySelectButton',
    PERSONAL_SELECT_BUTTON: 'PersonalSelectButton',
    HP_SMART_INSTALL_BUTTON: 'HpSmartInstallButton',
    GET_CODE_THROUGH_NETWORK_BUTTON: 'GetCodeThroughNetworkButton',
    GET_CODE_THROUGH_EWS_BUTTON: 'GetCodeThroughEwsButton',
    DECLINE_HP_PLUS_BUTTON: 'DeclineButton',
    BACK_TO_OFFER_BUTTON: 'BackToOfferButton',
    GO_TO_DASHBOARD: 'GoToDashboardButton',
    LEARN_MORE: 'LearnMoreButton',
    LEARN_MORE_HP_PLUS_OVERVIEW: 'HpPlusOverviewButton',
    LEARN_MORE_HP_PLUS_REQUIREMENTS: 'HpPlusRequirementsButton',
    LEARN_MORE_INSTANT_INK: 'InstantInkButton',
    LEARN_MORE_FOREST_FIRST: 'ForestFirstPrintingButton',
    LEARN_MORE_HP_SMART_ADVANCE: 'HpSmartAdvanceButton',
    LEARN_MORE_HP_SMART_PRO: 'HpSmartProButton',
    LEARN_MORE_SMART_SECURITY: 'SmartSecurityButton',
    VISIT_WEBSITE: 'VisitWebsiteButton'
  },
  LINKS: {
    HP_SMART_TOU: 'HpSmartTouLink',
    END_USER_AGREEMENT_LINK: 'EndUserAgreementLink',
    HP_SUPPORT: 'HpSupportLink',
    LEARN_ABOUT_HP_SMART: 'LearnAbtHpSmartLink',
    SUPPORTED_COUNTRIES: 'SupportedCountriesLink',
    LEARN_MORE: 'LearnMoreLink',
    INSTANT_INK_ONE: 'InstantInkOneLink',
    INSTALL_HP_SMART: 'InstallHpSmartLink',
    DRIVER_CONFIRM: 'DriverConfirmLink',
    DOWNLOAD_HP_EASY_START: 'DownloadHpEasyStartLink',
    HP_DRIVER_LINK: 'HpDriverLink',
    HP_EASY_START_DOWNLOAD: 'HpEasyStartDowloadLink',
    HP_EASY_START_LINK: 'HpEasyStartLink',
    LEARN_HP_SMART: 'LearnHpSmartLink',
    HP_SMART_DRIVER: 'HpSmartDriverLink',
    INSTALL_DRIVER: 'InstallDriverLink',
    CANT_FIND_PAIRING_CODE: 'CantFindPairingCodeLink',
    SETUP_WITHOUT_HP_CLOUD_LINK: 'SetupWithoutHpCloudLink',
    SETUP_FOR_PERSONAL_USE: 'SetupForPersonalUse',
    FEATURE_LINK: 'FeatureLink',
    HP_PLUS_BENEFITS_LINK: 'HpPlusBenefitsLink',
    SIGN_IN: 'SignInLink'
  },
  ACCORDIONS: {
    WHAT_IS_HP_PLUS: 'WhatIsHpPlusAccordion',
    IS_HP_SUBS: 'IsHpSubsAccordion',
    IS_THERE_COST: 'IsThereCostAccordion',
    CAN_CANCEL: 'CanCancelAccordion',
    HP_VS_II: 'HpVsIiAccordion',
    CAN_ACTIVATE_LATER: 'CanActivateLaterAccordion',
    HW_SETUP: 'HwSetUpAccordion',
    WHAT_ARE_REQS: 'WhAreTheRqrsAccordion',
    WHAT_IS_ORG_HP_INK: 'WhIsOrgnHpInkAccordion',
    WHAT_IF_NON_ORG_HP_INK: 'WhHpnIfUseNonOrgnHpInkAccordion',
    WHY_KEEP_PRINTER_CONNECTED: 'WhNdToKpMyPrntrAccordion',
    WHAT_IF_DISCONNECTED: 'WhIfMyHpPrntrDscnctdAccordion',
    CONNECTION_USING_USB: 'HpPrntrWrkWithUsbAccordion',
    WHAT_IS_II: 'WhIsIiAccordion',
    HOW_II_WORKS: 'HwIiWorksAccordion',
    WHEN_RECEIVE_II: 'HwSoonRcvInkAccordion',
    PLANS_AND_COST_II: 'WhPlansAndCostsAccordion',
    CANCEL_II: 'CanCancelAccordion',
    CREDIT_CARD_REQ_II: 'IsCreditCardRqrdAccordion'
  },
  MODES: {
    SCREEN: {
      INITIATION: 'InitiationScreen',
      TOKEN: 'TokenScreen',
      RTP: 'RtpScreen',
      IOT: 'IotScreen',
      REGISTER_DEVICE: 'RegisterDeviceScreen',
      IRIS: 'IrisScreen',
      COMPANY: 'Company',
      ITALY: 'Italy',
      REST_OF_WORLD: 'Row',
      CREATE_SCAN_TO_EMAIL: 'CreateScanToEmail',
      CREATE_SHORTCUTS_SESSION: 'CreateShortcutsSession'
    },
    PROVIDED: {
      [true]: 'Provided',
      [false]: 'Blank'
    },
    DOMAIN: {
      [true]: 'DomainExist',
      [false]: 'DomainNotExist'
    },
    ORGANIZATION: {
      [true]: 'ExistingOrg',
      [false]: 'NewOrg'
    }
  },
  VERSION: '1.4.0',
  ENVELOPE_VERSION: '1.4.0',
  EVENT_DETAIL_TYPE:
    'com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1',
  EVENT_CATEGORY: 'simpleUi'
}

export const SPLUNK_RUM_FIELDS = {
  WORKFLOW: 'workflow.name',
  PRINTER_COUNTRY: 'printer.country',
  DHP_PATH: 'dhp.path',
  MODEL_NAME: 'model.name',
  FLOW_TYPE: 'flow.type',
  RESPONSE_STATUS: 'response.status',
  RESPONSE_BODY: 'response.body'
}

export const SPLUNK_RUM_RUN_TIME_WORKFLOWS = {
  FW_UPDATE_AUTO: 'AutoFwUpdateChoice',
  FW_UPDATE_NOTIFY: 'NotifyFwUpdateChoice'
}

export const SPLUNK_RUM_CUSTOM_EVENTS = {
  DHP_ERRORS: {
    moduleName: '$currentServiceId-dhp-error',
    workflow: 'DhpError'
  },
  FW_UPDATE_CHOICE: {
    moduleName: 'onbp-jwv-app-auto-firmware-update-notice'
  },
  SHORTCUTS_SESSION_CREATION_ERROR: {
    moduleName: '$currentServiceId-shortcuts',
    workflow: 'ShortcutsSessionCreationError'
  },
  SHORTCUT_CREATION_ERROR: {
    moduleName: '$currentServiceId-shortcuts',
    workflow: 'ShortcutCreationError'
  },
  ERRORS: {
    moduleName: '$currentServiceId-error'
  }
}

export const SUPPORTED_LOCALE_SELECTION_ITEMS = [
  { value: 'bg/bg', label: 'български' },
  { value: 'cz/cs', label: 'Čeština' },
  { value: 'dk/da', label: 'Dansk' },
  { value: 'at/de', label: 'Deutsch' },
  { value: 'gr/el', label: 'ελληνικά' },
  { value: 'us/en', label: 'English' },
  { value: 'es/es', label: 'Español' },
  { value: 'ee/et', label: 'Estonian' },
  { value: 'fi/fi', label: 'Suomi' },
  { value: 'ca/fr', label: 'Français (Canada)' },
  { value: 'fr/fr', label: 'Français (France)' },
  { value: 'hr/hr', label: 'Hrvatski' },
  { value: 'hu/hu', label: 'Magyar' },
  { value: 'it/it', label: 'Italiano' },
  { value: 'jp/ja', label: '日本語' },
  { value: 'kr/ko', label: '한국어' },
  { value: 'lt/lt', label: 'Lithuanian' },
  { value: 'lv/lv', label: 'Latvian' },
  { value: 'nl/nl', label: 'Nederlands' },
  { value: 'no/nb', label: 'Bokmål' },
  { value: 'pl/pl', label: 'Polski' },
  { value: 'pt/pt', label: 'Português' },
  { value: 'ro/ro', label: 'Română' },
  { value: 'ru/ru', label: 'Pусский' },
  { value: 'sk/sk', label: 'Slovenčina' },
  { value: 'si/sl', label: 'Slovenščina' },
  { value: 'se/sv', label: 'Svenska' },
  { value: 'tr/tr', label: 'Türkçe' },
  { value: 'cn/zh', label: '简体中文' },
  { value: 'tw/zh', label: '繁體中文 (臺灣)' },
  { value: 'hk/zh', label: '繁體中文 (香港)' }
]

export const ERROR_SUFFIX_AND_RELATED_MODE = {
  _SP: ANALYTICS.MODES.SCREEN.INITIATION,
  _CP: ANALYTICS.MODES.SCREEN.TOKEN,
  _RS: ANALYTICS.MODES.SCREEN.RTP,
  _RC: ANALYTICS.MODES.SCREEN.RTP,
  _VD: ANALYTICS.MODES.SCREEN.IOT,
  _RD: ANALYTICS.MODES.SCREEN.REGISTER_DEVICE,
  _GI: ANALYTICS.MODES.SCREEN.IRIS,
  _SC: ANALYTICS.MODES.SCREEN.IRIS
}

export const SOFTWARE_TYPES = {
  FULLKARNAK: 'FULLKARNAK',
  NOT_SUPPORTED: 'NOT SUPPORTED'
}

export const TRACKING_IDENTIFIER =
  ANALYTICS.SCREENS.ENTER_PAIRING_CODE.screenName + '-' + Date.now()

export const DHP_REQUEST_STATUS = {
  PENDING: 'PENDING',
  NOT_STARTED: 'NOT_STARTED',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED'
}

export const DHP_SERVICES = {
  GET_SYSTEM_CONFIG: {
    method: 'GET',
    cdm: {
      serviceGun: 'com.hp.cdm.service.system.version.1',
      rel: 'identity'
    },
    ledm: {
      resourceType: 'ledm:hpLedmProductConfigDyn'
    }
  },
  SET_FW_UPDATE_CHOICE: {
    cdm: {
      method: 'PATCH',
      serviceGun: 'com.hp.cdm.service.firmwareUpdate.version.2',
      rel: 'configuration'
    },
    ledm: {
      method: 'PUT',
      bodyTemplate: FirmwareUpdateConfigBodyXml,
      manifest: {
        resourceType: 'ledm:hpLedmFirmwareUpdateManifest',
        uriResourceTypeKey: 'fwudyn:FirmwareUpdateElementType',
        nodes: [
          {
            resourceType: 'FirmwareUpdate',
            resourceUri: '/WebFWUpdate'
          },
          {
            resourceType: 'FirmwareUpdateConfig'
          }
        ]
      }
    }
  },
  GET_DEVICE_SETUP_STATUS: {
    method: 'GET',
    cdm: {
      serviceGun: 'com.hp.cdm.service.deviceSetup.version.1',
      rel: 'status'
    }
  },
  SET_DEVICE_SETUP_ACTION: {
    method: 'PATCH',
    cdm: {
      serviceGun: 'com.hp.cdm.service.deviceSetup.version.1',
      rel: 'status'
    }
  }
}

export const ACTION_AFU_PROMPT = 'actionAfuPrompt'

export const LOCALES_NOT_SUPPORTED_ON_ECP = [
  'ar-sa',
  'he-il',
  'id-id',
  'kk-kz',
  'mt-mt',
  'sr-rs',
  'th-th',
  'uk-ua',
  'vi-vn'
]

export const SMB_DOMAINS = {
  [Stack.pie]: 'https://smb.pie.portalshell.int.hp.com',
  [Stack.stage]: 'https://smb.stage.portalshell.int.hp.com',
  [Stack.prod]: 'https://admin.hpsmart.com/'
}
