import React, { useContext } from 'react'
import { PrinterContext } from '@/store'
import IconCheckmarkCircle from '@veneer/core/dist/scripts/icons/icon_checkmark_circle'

import classes from './PrinterView.module.scss'

export function PrinterView({ checkmark }) {
  const { printerImage, modelName } = useContext(PrinterContext)
  return (
    <div className={classes.printerViewContainer}>
      <div className={classes.imageContainer}>
        {checkmark && (
          <IconCheckmarkCircle
            filled
            size={54}
            className={classes.iconCheckmark}
          />
        )}
        <img width={292} src={printerImage} alt="Printer model image" />
      </div>
      <h6 className={classes.modelName}>{modelName}</h6>
    </div>
  )
}
