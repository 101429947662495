// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1O95HxLiSPUzsgQelAIYjK{display:flex;flex-direction:column;align-items:center;min-height:375px}._1O95HxLiSPUzsgQelAIYjK .nlIGEQ4BHLvAtJILmXKnh{font-size:20px;font-weight:400;line-height:28px;margin:0}._1O95HxLiSPUzsgQelAIYjK ._1504d_VINZdIW1M8ibnsSR{position:relative;margin:15px 0}._1O95HxLiSPUzsgQelAIYjK ._1504d_VINZdIW1M8ibnsSR ._29HfzQ0ICohZqdOb07jVGW{position:absolute;color:green;top:8px;left:8px}\n", "",{"version":3,"sources":["webpack://./src/components/UI/Smallville/PrinterView/PrinterView.module.scss"],"names":[],"mappings":"AAAA,yBAAsB,YAAY,CAAC,qBAAqB,CAAC,kBAAkB,CAAC,gBAAgB,CAAC,gDAAiC,cAAc,CAAC,eAAe,CAAC,gBAAgB,CAAC,QAAQ,CAAC,kDAAsC,iBAAiB,CAAC,aAAa,CAAC,2EAAqD,iBAAiB,CAAC,WAAW,CAAC,OAAO,CAAC,QAAQ","sourcesContent":[".printerViewContainer{display:flex;flex-direction:column;align-items:center;min-height:375px}.printerViewContainer .modelName{font-size:20px;font-weight:400;line-height:28px;margin:0}.printerViewContainer .imageContainer{position:relative;margin:15px 0}.printerViewContainer .imageContainer .iconCheckmark{position:absolute;color:green;top:8px;left:8px}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"printerViewContainer": "_1O95HxLiSPUzsgQelAIYjK",
	"modelName": "nlIGEQ4BHLvAtJILmXKnh",
	"imageContainer": "_1504d_VINZdIW1M8ibnsSR",
	"iconCheckmark": "_29HfzQ0ICohZqdOb07jVGW"
};
export default ___CSS_LOADER_EXPORT___;
